import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/allto.png";

const HeaderUser = () => {
  return (
    <header className="main-header">
      <div className="header-content">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h1 className="header-title">Controle de Remessa</h1>
        <div className="header-actions">
          <button className="button-primary">
            <FontAwesomeIcon icon={faBell} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderUser;
