/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import ContentChoice from "../../components/cards/ContentChoice";
import Footer from "../../components/includes/Footer";

export default () => {
  return (
    <div>
      <div className="container home">
        <div className="row">
          <div className="col-6">
            <ContentChoice
              title="NOVA REMESSA"
              text="Clique abaixo para iniciar a conferência dos itens de uma nova remessa."
              textButton="INICIAR"
              isHome={true}
            />
          </div>

          <div className="col-6">
            <ContentChoice
              title="REMESSA EXISTENTE"
              text="Clique para retomar a conferência dos itens de uma remessa já iniciada."
              textButton="CONTINUAR"
              isHome={true}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
