/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { socketInstance } from "../../services/WebSocket";

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const [dispatchValue, setDispatchValue] = useState("");
  const inputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (showModal && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [showModal]);

  useEffect(() => {
    if (!socketInstance) return;

    socketInstance.on("joinedDispatch", (data) => {
      console.log("Joined dispatch:", data);

      if (data.scannedItemsCount === 0) {
        navigate(`/new?dispatch=${dispatchValue}`, {
          state: { totalItems: data.totalItemsCount },
        });
      } else {
        navigate(`/existent?dispatch=${dispatchValue}`, {
          state: {
            remainingItems: data.remainingItems,
            scannedItems: data.scannedItems,
          },
        });
      }
    });

    socketInstance.on("joinError", (error) => {
      const { message } = error || {};
      alert(message || "An unknown error occurred.");
    });

    socketInstance.on("scanError", (error) => {
      alert(error?.data?.message || "An error occurred during scanning.");
    });

    return () => {
      socketInstance.off("joinedDispatch");
      socketInstance.off("joinError");
      socketInstance.off("scanError");
    };
  }, [dispatchValue, navigate]);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    setDispatchValue(e.target.value);
  };

  const handleSubmit = () => {
    if (socketInstance) {
      socketInstance.emit("joinDispatch", dispatchValue);
    }
    closeModal();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSubmit();
  };

  return (
    <div className={`content ${props.isHome ? "home-content" : ""}`}>
      <p className="title">{props.title}</p>
      <p className="text">{props.text}</p>

      <button className="button-primary" onClick={openModal}>
        {props.textButton}
      </button>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Leia o código de barras da remessa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Código de barras</Form.Label>
            <Form.Control
              type="number"
              placeholder="12345678"
              value={dispatchValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              ref={inputRef}
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
