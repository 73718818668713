import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true",
  );

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      // Only run on initial mount
      const userLoggedIn = localStorage.getItem("isLoggedIn") === "true";
      console.log("User Logged In:", userLoggedIn);
      setIsLoggedIn(userLoggedIn);
      initialized.current = true;
    }
  }, []);

  const login = (userData) => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("userData", JSON.stringify(userData));
    localStorage.setItem("access_token", userData.access_token);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    localStorage.removeItem("access_token");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
