/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/includes/Footer";
import { socketInstance } from "../../services/WebSocket";

const New = () => {
  const [readItems, setReadItems] = useState([]);
  const [dispatchDetails, setDispatchDetails] = useState({
    receiver_name: "",
    shipment_priority: "",
    dispatch_id: "",
    total_items: 0,
  });
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [dispatchId, setDispatchId] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [totalItems, setTotalItems] = useState(
    location.state?.totalItems || {},
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!socketInstance) return;

    const params = new URLSearchParams(location.search);
    const dispatch = params.get("dispatch");

    if (dispatch) {
      setDispatchId(dispatch);
    }

    const handleItemScanned = (data) => {
      const quantity = data.scannedQuantity;

      if (typeof quantity !== "number") {
        console.error("Invalid quantity in data:", data);
        return;
      }

      setReadItems((prev) => {
        const existingIndex = prev.findIndex(
          (item) => item.itemCode === data.itemCode,
        );

        if (existingIndex !== -1) {
          const updatedItems = [...prev];
          const currentItem = updatedItems[existingIndex];
          if (currentItem.scannedQuantity < currentItem.totalQuantity) {
            updatedItems[existingIndex] = {
              ...currentItem,
              scannedQuantity: currentItem.scannedQuantity + quantity,
            };
          }
          return updatedItems;
        }

        return [
          ...prev,
          {
            itemCode: data.itemCode,
            description: data.description,
            scannedQuantity: quantity,
            totalQuantity: data.totalQuantity,
          },
        ];
      });
    };

    const handleScanError = (error) => {
      console.error("Scan error:", error);
      alert(error.message || "An unknown error occurred during scanning.");
      inputRef.current?.focus();
    };

    if (socketInstance) {
      socketInstance.on("itemScanned", handleItemScanned);
      socketInstance.on("scanError", handleScanError);

      // Add handler for over-scan alerts
      socketInstance.on("overScanAlert", (data) => {
        alert(`Atenção: Quantidade máxima excedida para o item ${data.itemCode}
                  Descrição: ${data.description}
                  Quantidade escaneada: ${data.scannedQuantity}
                  Quantidade total permitida: ${data.totalQuantity}

                  O item não será contabilizado.`);
        inputRef.current?.focus();
      });

      return () => {
        socketInstance.off("itemScanned", handleItemScanned);
        socketInstance.off("scanError", handleScanError);
        socketInstance.off("overScanAlert");
        socketInstance.off("sessionPaused");
        socketInstance.off("sessionResumed");
        socketInstance.off("sessionCompleted");
        socketInstance.off("sessionError");
      };
    }
  }, [location]);

  useEffect(() => {
    if (dispatchId) {
      fetch(`${apiUrl}/api/dispatch/${dispatchId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch dispatch data");
          }
          return response.json();
        })
        .then((data) => {
          setDispatchDetails({
            receiver_name: data.items[0]?.receiver_name || "N/A",
            shipment_priority: data.items[0]?.shipment_priority || "N/A",
          });
        })
        .catch((error) => {
          console.error("Error fetching dispatch data:", error);
        });
    }
  }, [dispatchId, apiUrl]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!inputValue.includes("@")) {
      alert("Código de item inválido");
      inputRef.current?.focus();
      return;
    }

    if (socketInstance && inputValue) {
      socketInstance.emit("scanItem", { qrCode: inputValue });
      setInputValue("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const pauseDispatch = () => {
    if (socketInstance) {
      socketInstance.emit("pauseSession");
      setIsPaused(true);
    }
  };

  const continueDispatch = () => {
    if (socketInstance) {
      socketInstance.emit("resumeSession");
      setIsPaused(false);
    }
  };

  const goToEndPage = () => {
    navigate(`/end?dispatch=${dispatchId}`, { state: { from: "Existent" } });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSubmit(e);
  };

  return (
    <div>
      <div className="container existent">
        <div className="row">
          <div className="col-6">
            <div className="content">
              <p className="text-1">Leia o código de barras do item:</p>
              <form onSubmit={handleSubmit} className="form">
                <input
                  type="string"
                  className="input"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  ref={inputRef}
                  placeholder="12345678"
                />
              </form>
            </div>

            <div className="content margin-top">
              <p className="text-1">Dados da remessa:</p>
              <ul className="list-item">
                <li className="text-2">
                  <strong>Destinatário:</strong> {dispatchDetails.receiver_name}
                </li>
                <li className="text-2">
                  <strong>Prioridade de envio:</strong>{" "}
                  {dispatchDetails.shipment_priority}
                </li>
                <li className="text-2">
                  <strong>Remessa:</strong> {dispatchDetails.dispatch_id}
                </li>
                <li className="text-2">
                  <strong>Total de itens:</strong> {totalItems}
                </li>
              </ul>
            </div>

            {!isPaused && (
              <button
                className="button-primary margin-top"
                style={{ float: "right" }}
                onClick={pauseDispatch}
              >
                PAUSA REMESSA
              </button>
            )}

            {isPaused && (
              <button
                className="button-primary margin-top"
                style={{ float: "right" }}
                onClick={continueDispatch}
              >
                CONTINUAR REMESSA
              </button>
            )}
          </div>
          <div className="col-6">
            {readItems.length > 0 && (
              <div className="content">
                <p className="text-1">Itens lidos:</p>
                <ul className="list">
                  {readItems.map((item, index) => (
                    <li key={index} className="text-2">
                      <strong>Descrição:</strong> {item.description} <br />
                      <strong>Quantidade escaneada:</strong>{" "}
                      {item.scannedQuantity} <br />
                      <strong>Quantidade total:</strong> {item.totalQuantity}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer linkBack="/" linkGo={goToEndPage} leaveDispatch />
    </div>
  );
};
export default New;
