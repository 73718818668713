// WebSocket.jsx
import { useEffect } from "react";
import io from "socket.io-client";

let socketInstance;

const WebSocketConnection = () => {
  const socketUrl = process.env.REACT_APP_SOCKET_IO;

  useEffect(() => {
    // Create socket instance only if not already defined
    if (!socketInstance) {
      socketInstance = io(socketUrl);

      socketInstance.on("connect", () => {
        console.log("Connected to WebSocket server");
      });

      socketInstance.on("disconnect", () => {
        console.log("WebSocket disconnected");
      });
    }

    // Disconnect on tab/browser close
    const handleBeforeUnload = () => {
      socketInstance.disconnect();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [socketUrl]);

  return null;
};

export { WebSocketConnection, socketInstance };
