/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";

export default (props) => {
  const [file, setFile] = useState(null);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/import/csv`,
          {
            method: "POST",
            body: formData,
          }
        );
        const result = await response.json();

        alert(result.message);
        console.log(result);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div>
      <h1>Enviar CSV</h1>

      <form className="form" onSubmit={handleOnSubmit}>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
          className="input"
        />

        <button type="submit" className="button-primary">
          Enviar
        </button>
      </form>
    </div>
  );
};
