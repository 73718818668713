import React, { useState, useEffect } from "react";
import "../../assets/sass/components/includes/sessionsTable.scss";

const SessionsTable = () => {
  const [sessions, setSessions] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/scanning-sessions`);
      if (!response.ok) {
        throw new Error("Failed to fetch sessions");
      }
      const data = await response.json();
      setSessions(data);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  const getRowClassName = (status) => {
    switch (status) {
      case "active":
        return "session-row-active";
      case "paused":
        return "session-row-paused";
      case "completed":
        return "session-row-completed";
      default:
        return "";
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return new Date(dateString).toLocaleString();
  };

  return (
    <div className="sessions-table">
      <h2>Sessões de Leitura</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Remessa</th>
            <th>Status</th>
            <th>Iniciado em</th>
            <th>Pausado em</th>
            <th>Retomado em</th>
            <th>Finalizado em</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session) => (
            <tr
              key={session.dispatchId}
              className={`table-row ${getRowClassName(session.status)}`}
            >
              <td>{session.dispatchId}</td>
              <td>
                <span className={`status-${session.status}`}>
                  {session.status === "active" && "Ativo"}
                  {session.status === "paused" && "Pausado"}
                  {session.status === "completed" && "Finalizado"}
                </span>
              </td>
              <td>{formatDate(session.startedAt)}</td>
              <td>{formatDate(session.pausedAt)}</td>
              <td>{formatDate(session.resumedAt)}</td>
              <td>{formatDate(session.completedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SessionsTable;
