import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { socketInstance } from "../../services/WebSocket";

const Footer = (props) => {
  const navigate = useNavigate();
  const showAdmin = props.showAdmin !== undefined ? props.showAdmin : true;

  const linkAdmin = () => {
    navigate("/admin");
  };

  const linkBack = () => {
    // Don't leave dispatch if we're in the process of completing it
    if (!props.linkEnd && props.leaveDispatch && socketInstance) {
      socketInstance.emit("leaveDispatch");
    }
    navigate(props.linkBack);
  };

  const linkGo = () => {
    if (typeof props.linkGo === "function") {
      props.linkGo();
    } else {
      navigate(props.linkGo);
    }
  };

  const linkEnd = () => {
    if (props.dispatchId && socketInstance) {
      // Remove existing listeners
      socketInstance.off("sessionCompleted");
      socketInstance.off("sessionError");

      // Add one-time listeners
      socketInstance.once("sessionCompleted", (data) => {
        console.log("Received sessionCompleted:", data);
        if (data && data.status === "completed") {
          if (props.leaveDispatch) {
            socketInstance.emit("leaveDispatch");
          }
          navigate(props.linkEnd);
        }
      });

      socketInstance.once("sessionError", (data) => {
        console.log("Received sessionError:", data);
        if (data && data.message) {
          alert("Erro ao encerrar remessa: " + data.message);
        }
      });

      // Complete session first, leave only after confirmation
      socketInstance.emit("completeSession", props.dispatchId);
    }
  };

  return (
    <div className="container footer">
      <div className="row">
        {showAdmin && (
          <div className="col-6 link" style={{ textAlign: "left" }}>
            <button className="button-secondary" onClick={linkAdmin}>
              <FontAwesomeIcon icon={faScrewdriverWrench} />
            </button>
          </div>
        )}
        {props.linkBack && !props.linkGo && !props.linkEnd && (
          <div className="col-6 link" style={props.linkBackStyle}>
            <button className="button-secondary" onClick={linkBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
        )}
        {props.linkGo && !props.linkBack && (
          <div className="col-6 link">
            <button className="button-primary" onClick={linkGo}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        )}
        {props.linkGo && props.linkBack && (
          <>
            <div className="col-6 link">
              <button className="button-secondary" onClick={linkBack}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button className="button-primary" onClick={linkGo}>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </>
        )}
        {props.linkEnd && props.linkBack && (
          <>
            <div className="col-6 link">
              <button className="button-secondary" onClick={linkBack}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button className="button-primary" onClick={linkEnd}>
                FINALIZAR
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
