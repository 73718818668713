/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import Footer from "../components/includes/Footer";

export default () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { isLoggedIn, login } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/admin");
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username, password: password }),
      });

      if (response.ok) {
        const data = await response.json();
        login(data);
        navigate("/admin");
      } else {
        const errorData = await response.json();
        alert(`Falha no login: ${errorData.message}`);
      }
    } catch (error) {
      alert("Erro ao tentar fazer login.", error);
    }
  };

  return (
    <>
      <div className="container login">
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit} className="form">
              <input
                type="text"
                className="field"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Nome de usuário"
              />

              <input
                type="password"
                className="field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Senha"
              />
              <input type="submit" className="button-primary" value="Login" />
            </form>
          </div>
        </div>
      </div>

      <Footer
        showAdmin={false}
        linkBack="/"
        linkBackStyle={{ textAlign: "left" }}
      />
    </>
  );
};
