import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Admin from "../pages/admin";
import Home from "../pages/home";
import New from "../pages/dispatch/New";
import Existent from "../pages/dispatch/Existent";
import Login from "../services/Login";
import End from "../pages/dispatch/End";
import Teste from "../pages/teste";

export const UserRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/new/*" element={<New />} />
      <Route path="/existent/*" element={<Existent />} />
      <Route path="/end" element={<End />} />
      <Route path="/teste" element={<Teste />} />
      <Route path="/admin/login" element={<Login />} />

      {/* Protected Admin Route */}
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
