/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import {
  faFileCsv,
  faHouse,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default (props) => {
  return (
    <aside className="left-menu">
      <ul>
        <li>
          <a
            onClick={() => props.setSelectedComponent("Dashboard")}
            className={
              props.selectedComponent === "Dashboard" ? "link active" : "link"
            }
          >
            <FontAwesomeIcon icon={faHouse} />
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <a
            onClick={() => props.setSelectedComponent("CsvImport")}
            className={
              props.selectedComponent === "CsvImport" ? "link active" : "link"
            }
          >
            <FontAwesomeIcon icon={faFileCsv} />
            <span>Importação CSV</span>
          </a>
        </li>
        <li>
          <a
            onClick={() => props.setSelectedComponent("Sessions")}
            className={
              props.selectedComponent === "Sessions" ? "link active" : "link"
            }
          >
            <FontAwesomeIcon icon={faHistory} />
            <span>Sessões de Leitura</span>
          </a>
        </li>
      </ul>
    </aside>
  );
};
