/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import Header from "../../components/includes/Header";
import LeftMenu from "../../components/menu/LeftMenu";
import Dashboard from "../../components/includes/Dashboard";
import CsvImport from "../../components/imports/CsvImport";
import SessionsTable from "../../components/includes/SessionsTable";

const Admin = () => {
  const [selectedComponent, setSelectedComponent] = useState("Dashboard");

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Dashboard":
        return <Dashboard />;
      case "CsvImport":
        return <CsvImport />;
      case "Sessions":
        return <SessionsTable />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="admin">
      <Header />

      <main>
        <LeftMenu setSelectedComponent={setSelectedComponent} />

        <div className="container main-content">
          <div className="row">
            <div className="col-12">{renderComponent()}</div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Admin;
