import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/includes/HeaderUser";

import "../src/assets/sass/main.scss";

import { AuthProvider } from "../src/auth/AuthContext";
import { UserRoutes } from "../src/routes/Routes";
import { WebSocketConnection } from "./services/WebSocket";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <WebSocketConnection />

        <div className="App">
          <UserRoutes />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
