/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/includes/Footer";
import { socketInstance } from "../../services/WebSocket";

export default () => {
  const [dispatchDetails, setDispatchDetails] = useState({
    receiver_name: "",
    shipment_priority: "",
    dispatch_id: "",
    total_items: 0,
  });
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [dispatchId, setDispatchId] = useState("");
  const [remainingItems, setRemainingItems] = useState(
    location.state?.remainingItems || {},
  );
  const [scannedItems, setScannedItems] = useState(
    location.state?.scannedItems || [],
  );
  const [isPaused, setIsPaused] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (!socketInstance) return;

    const handleJoinedDispatch = (data) => {
      console.log("Joined dispatch with data:", data);
      if (data.data) {
        // Set scanned items from joinDispatch response
        if (data.data.scannedItems) {
          setScannedItems(data.data.scannedItems);
        }
        // Set remaining items from joinDispatch response
        if (data.data.remainingItems) {
          setRemainingItems(data.data.remainingItems);
        }
      }
    };

    socketInstance.on("joinedDispatch", handleJoinedDispatch);

    // Emit joinDispatch when component mounts and we have a dispatchId
    if (dispatchId) {
      socketInstance.emit("joinDispatch", dispatchId);
    }

    return () => {
      socketInstance.off("joinedDispatch", handleJoinedDispatch);
    };
  }, [dispatchId]);

  useEffect(() => {
    inputRef.current.focus();

    let alertShown = false;

    const handleItemScanned = (data) => {
      console.log("Received data:", data);
      const quantity = data.scannedQuantity;

      if (typeof quantity !== "number") {
        console.error("Invalid quantity in data:", data);
        return;
      }

      // Update remainingItems
      setRemainingItems((prev) => {
        const updatedRemainingItems = { ...prev };
        if (updatedRemainingItems[data.itemCode] > 0) {
          updatedRemainingItems[data.itemCode] -= quantity;
        }
        return updatedRemainingItems;
      });

      // Update scannedItems
      setScannedItems((prev) => {
        const existingIndex = prev.findIndex(
          (item) => item.itemCode === data.itemCode,
        );

        if (existingIndex !== -1) {
          const updatedItems = [...prev];
          updatedItems[existingIndex] = {
            ...updatedItems[existingIndex],
            scannedQuantity:
              updatedItems[existingIndex].scannedQuantity + quantity,
          };
          return updatedItems;
        }

        return [
          ...prev,
          {
            itemCode: data.itemCode,
            description: data.description,
            scannedQuantity: quantity,
            totalQuantity: data.totalQuantity,
          },
        ];
      });
    };

    const handleScanError = (error) => {
      console.error("Scan error:", error);
      alert(
        error.message || "Ocorreu um erro desconhecido durante o escaneamento.",
      );
      inputRef.current?.focus();
    };

    if (socketInstance) {
      socketInstance.on("itemScanned", handleItemScanned);
      socketInstance.on("scanError", handleScanError);

      // Add handler for over-scan alerts
      socketInstance.on("overScanAlert", (data) => {
        alert(`Atenção: Quantidade máxima excedida para o item ${data.itemCode}
              Descrição: ${data.description}
              Quantidade escaneada: ${data.scannedQuantity}
              Quantidade total permitida: ${data.totalQuantity}

              O item não será contabilizado.`);
        inputRef.current?.focus();
      });

      return () => {
        socketInstance.off("itemScanned", handleItemScanned);
        socketInstance.off("scanError", handleScanError);
        socketInstance.off("overScanAlert");
        socketInstance.off("sessionPaused");
        socketInstance.off("sessionResumed");
        socketInstance.off("sessionCompleted");
        socketInstance.off("sessionError");
      };
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const dispatch = params.get("dispatch");

    if (dispatch) {
      setDispatchId(dispatch);
    }
  }, [location]);

  // This useEffect should only handle dispatch details, not remainingItems
  useEffect(() => {
    if (dispatchId) {
      fetch(`${apiUrl}/api/dispatch/${dispatchId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch dispatch data");
          }
          return response.json();
        })
        .then((data) => {
          setDispatchDetails({
            receiver_name: data.items[0]?.receiver_name || "N/A",
            shipment_priority: data.items[0]?.shipment_priority || "N/A",
            dispatch_id: data.dispatch_id,
            total_items: data.total_items,
          });
        })
        .catch((error) => {
          console.error("Error fetching dispatch data:", error);
        });
    }
  }, [dispatchId, apiUrl]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const pauseDispatch = () => {
    if (socketInstance) {
      socketInstance.emit("pauseSession");
      setIsPaused(true);
    }
  };

  const continueDispatch = () => {
    if (socketInstance) {
      socketInstance.emit("resumeSession");
      setIsPaused(false);
    }
  };

  const debounceRef = useRef(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!inputValue.includes("@")) {
      alert("Código de item inválido");
      inputRef.current?.focus();
      return;
    }

    if (debounceRef.current) return;
    debounceRef.current = true;
    setTimeout(() => (debounceRef.current = false), 500);

    socketInstance.emit("scanItem", { qrCode: inputValue });
    setInputValue("");
    inputRef.current.focus();
  };

  const goToEndPage = () => {
    navigate(`/end?dispatch=${dispatchId}`, { state: { from: "Existent" } });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSubmit(e);
  };

  return (
    <div>
      <div className="container existent">
        <div className="row">
          <div className="col-6">
            <div className="content">
              <p className="text-1">Leia o código de barras do item:</p>
              <form onSubmit={handleSubmit} className="form">
                <input
                  type="string"
                  className="input"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  ref={inputRef}
                  placeholder="12345678"
                />
              </form>
            </div>

            {!isPaused && (
              <button
                className="button-primary margin-top"
                style={{ float: "right" }}
                onClick={pauseDispatch}
              >
                PAUSA REMESSA
              </button>
            )}

            {isPaused && (
              <button
                className="button-primary margin-top"
                style={{ float: "right" }}
                onClick={continueDispatch}
              >
                CONTINUAR REMESSA
              </button>
            )}
          </div>

          <div className="col-6">
            <div className="content">
              <p className="text-1">Dados da remessa:</p>
              <ul className="list-item">
                <li className="text-2">
                  <strong>Destinatário:</strong> {dispatchDetails.receiver_name}
                </li>
                <li className="text-2">
                  <strong>Prioridade de envio:</strong>{" "}
                  {dispatchDetails.shipment_priority}
                </li>
                <li className="text-2">
                  <strong>Remessa:</strong> {dispatchDetails.dispatch_id}
                </li>
                <li className="text-2">
                  <strong>Total de itens:</strong> {dispatchDetails.total_items}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-6">
            {Object.entries(remainingItems).some(
              ([_, quantity]) => quantity > 0,
            ) && (
              <div className="content">
                <p className="text-1">Itens da remessa não lidos:</p>
                <ul className="list-existent">
                  {Object.entries(remainingItems)
                    .filter(([_, quantity]) => quantity > 0) // Only show items with quantity > 0
                    .map(([itemCode, quantity], index) => (
                      <li key={index} className="text-2">
                        <strong>Código:</strong> {itemCode} <br />
                        <strong>Quantidade:</strong> {quantity}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-6">
            {scannedItems.length > 0 && (
              <div className="content">
                <p className="text-1">Itens lidos:</p>
                <ul className="list">
                  {scannedItems.map((item, index) => (
                    <li key={index} className="text-2">
                      <strong>Código:</strong> {item.itemCode} <br />
                      <strong>Quantidade escaneada:</strong>{" "}
                      {item.scannedQuantity} <br />
                      <strong>Quantidade total:</strong> {item.totalQuantity}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer linkBack="/" linkGo={goToEndPage} leaveDispatch />
    </div>
  );
};
