import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/includes/Footer";
import { socketInstance } from "../../services/WebSocket";

const End = () => {
  const location = useLocation();
  const [dispatchId, setDispatchId] = useState("");
  const [linkBack, setLinkBack] = useState("");
  const [dispatchDetails, setDispatchDetails] = useState({
    receiver_name: "",
    shipment_priority: "",
    dispatch_id: "",
    total_items: 0,
  });

  const [dispatchReconciliationDetails, setDispatchReconciliationDetails] =
    useState({
      missingItems: {},
      extraItems: {},
      scannedItems: {},
      isComplete: false,
    });
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const dispatch = params.get("dispatch");

    if (dispatch) {
      setDispatchId(dispatch);

      // Set linkBack based on the navigation source
      if (location.state?.from === "Existent") {
        setLinkBack(`/existent?dispatch=${dispatch}`);
      } else if (location.state?.from === "New") {
        setLinkBack(`/new?dispatch=${dispatch}`);
      }

      // Fetch dispatch details
      fetch(`${apiUrl}/api/dispatch/${dispatch}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch dispatch data");
          }
          return response.json();
        })
        .then((data) => {
          setDispatchDetails({
            receiver_name: data.items[0]?.receiver_name || "N/A",
            shipment_priority: data.items[0]?.shipment_priority || "N/A",
            dispatch_id: data.dispatch_id,
            total_items: data.total_items,
          });
        })
        .catch((error) =>
          console.error("Error fetching dispatch data:", error),
        );

      // Fetch reconciliation data
      fetch(`${apiUrl}/api/dispatch/${dispatch}/reconciliation`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch reconciliation data");
          }
          return response.json();
        })
        .then((data) => {
          setDispatchReconciliationDetails({
            missingItems: data.missingItems || {},
            extraItems: data.extraItems || {},
            scannedItems: data.scannedItems || {},
            isComplete: data.isComplete || false,
          });
        })
        .catch((error) =>
          console.error("Error fetching reconciliation data:", error),
        );
    }

    // Cleanup function
    return () => {
      if (socketInstance) {
        socketInstance.emit("leaveDispatch");
        socketInstance.off("sessionCompleted");
        socketInstance.off("sessionError");
      }
    };
  }, [location, apiUrl]);

  return (
    <div>
      <div className="container end">
        <div className="row">
          <div className="col-12">
            <div className="content">
              <p className="title">Remessa: {dispatchDetails.dispatch_id}</p>
              <p className="text-1">Informações da remessa:</p>
              <ul className="list-item">
                <li className="text-2">
                  <strong>Destinatário:</strong> {dispatchDetails.receiver_name}
                </li>
                <li className="text-2">
                  <strong>Prioridade de envio:</strong>{" "}
                  {dispatchDetails.shipment_priority}
                </li>
                <li className="text-2">
                  <strong>Remessa:</strong> {dispatchDetails.dispatch_id}
                </li>
                <li className="text-2">
                  <strong>Total de itens:</strong> {dispatchDetails.total_items}
                </li>

                {Object.entries(dispatchReconciliationDetails.extraItems) && (
                  <li>
                    <strong className="text-2">Itens extra:</strong>{" "}
                    <ul className="list-extra">
                      {Object.entries(
                        dispatchReconciliationDetails.extraItems,
                      ).map(([itemCode, itemDetails], index) => (
                        <li key={index} className="text-2">
                          <strong>Código:</strong> {itemCode} <br />
                          <strong>Quantidade:</strong> {itemDetails.quantity}{" "}
                          <br />
                          <strong>Descrição:</strong> {itemDetails.description}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}

                <li className="text-2">
                  <strong>Status:</strong>{" "}
                  {dispatchReconciliationDetails.isComplete
                    ? "Completo"
                    : "Incompleto"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container end">
        <div className="row">
          <div className="col-6">
            <div className="content">
              <strong className="text-2">Itens faltando:</strong>
              <ul className="list-end">
                {dispatchReconciliationDetails.missingItems &&
                  Object.entries(
                    dispatchReconciliationDetails.missingItems,
                  ).map(([key, value]) => (
                    <li key={key} className="text-2">
                      <strong>Código:</strong>
                      {key} <br />
                      <strong> Descrição:</strong> {value.description}
                      <br />
                      <strong> Quantidade:</strong> {value.quantity}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {Object.keys(dispatchReconciliationDetails.scannedItems).length >
            0 && (
            <div className="col-6">
              <div className="content">
                <strong className="text-2">Itens lidos:</strong>
                <ul className="list-end">
                  {Object.entries(
                    dispatchReconciliationDetails.scannedItems,
                  ).map(([itemCode, itemDetails]) => (
                    <li key={itemCode} className="text-2">
                      <strong>Código:</strong> {itemCode} <br />
                      <strong>Descrição:</strong> {itemDetails.description}{" "}
                      <br />
                      <strong>Quantidade:</strong> {itemDetails.quantity}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer linkEnd="/" dispatchId={dispatchId} linkBack={linkBack} />
    </div>
  );
};

export default End;
